import React from 'react';
import AppProvider from './components/contextApp'
import Main from './components/Main'

function App() {
  return (
    <AppProvider>
      <Main />
    </AppProvider>
  );
}

export default App;
